import React, { createContext, useContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import useAxios from './useAxios'
import { set, del, get } from 'idb-keyval'

import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/messaging'
import 'firebase/firestore'

import { createStore, combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

import { apiUrl } from '../../config/apiOptions'
import { $get, $post } from './useAxiosV2'

/**
 * Abstracts away our actual auth provider (Fi  rebase), making it super easy to change providers in the future
 * Wrap provider <ProvideAuth> App component
 * Use useAuth to get details or set user details
 * @example https://usehooks.com/useAuth/
 * */
// Firebase credentials
firebase.initializeApp({
  apiKey: 'AIzaSyCB8EEys77Xpzy0ifvTmAKruXXUhRZoLeI',
  authDomain: 'intego-console.firebaseapp.com',
  projectId: 'intego-console',
  databaseURL: 'https://intego-console.firebaseio.com',
  storageBucket: 'intego-console.appspot.com',
  messagingSenderId: '1062916126930',
  appId: '1:1062916126930:web:643272ca1d6144fada950b',
  measurementId: 'G-DRY9Q6GMEE',
})

// initialize services
firebase.analytics()
firebase.firestore()
//firebase.messaging()

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
})

const initialState = {}
const store = createStore(rootReducer, initialState)

export const firestoreProps = {
  firebase,
  store: store,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
}

// Set data
const authContext = createContext()
const api = apiUrl

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

// Register notifications
const vapidKey = 'BNEYpKwxEuZSaJmvI-vcYoQx0YGA_V0ruUO5ApAkJs91RPIHBSYsm_pPBc6JZCQR_5tujMQYhJVpmkhp43azn9M'
export const managePushNotifications = async (subscribe, topics) => {
  const messaging = firebase.messaging()
  const token = await messaging.getToken({
    vapidKey: vapidKey,
  })

  if (token) {
    await $post(`${apiUrl}/notifications/registration`, {
      token: token,
      action: subscribe ? 'subscribe' : 'unsubscribe',
      topics: topics,
    })
  }
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const { $unsecurePost } = useAxios()

  const [user, setUser] = useState(null)
  const [data, setData] = useState(null)

  const getAllTenants = async (emailAddress) => {
    const response = await $unsecurePost(`${api}/manage/tenants`, { emailAddress: emailAddress })
    if (response.tenants && response.tenants.length > 0) {
      await set('tenants', response.tenants)
      return response.tenants
    } else {
      return null
    }
  }

  const getUserProps = async (uid) => {
    const response = await $get(`${api}/users/${uid}`)
    return response
  }


  const loginWithTenant = async (tenant, email, password) => {
    const auth = firebase.auth()
    auth.tenantId = tenant.id

    try {
      const authData = await auth.signInWithEmailAndPassword(email, password)
      await set('companyName', tenant.name)
      return authData
    } catch {
      return null
    }
  }

  const tryLogin = async (email, password) => {
    if (user) return user

    // any forced tenant?
    const forcedTenant = await get('forced-tenant')
    if (forcedTenant) {
      const authData = await loginWithTenant(forcedTenant, email, password)
      if (authData) {
        await set('tenant', forcedTenant)
        return authData
      }
    }

    const allTenants = await getAllTenants(email)
    for (let index = 0; index < allTenants.length; index++) {
      const aTenant = allTenants[index]
      const authData = await loginWithTenant(aTenant, email, password)
      if (authData) {
        await set('tenant', aTenant)
        return authData
      }
    }

    // we return null here if no success
    return null
  }

  // Login user with Email and Password
  const login = async (email, password) => {
    if (user) return user
    const authData = await tryLogin(email, password)

    const auth = firebase.auth()
    const idToken = await auth.currentUser.getIdToken(true)

    // Set data before fully mounted
    setData(authData)
    await verifyLogin(idToken, authData)
    setUser(authData.user)

    // Get userData to save in indexed DB
    const userData = await getUserProps(authData.user.uid)
    await set('user_props', userData)

    return authData.user
  }

  // Login user by token
  const authToken = async ({ result }) => {
    const auth = firebase.auth()
    auth.tenantId = result.tenantId

    await auth.signInWithCustomToken(result.authToken).catch((_error) => {
      // Handle Errors here.
    })
    const idToken = await auth.currentUser.getIdToken(true)

    await verifyLogin(idToken)
  }

  // Verify Login with the API
  const verifyLogin = async (token) => {
    await (window.localStorage.idToken = await token)
    return token
  }



  // Create user from panel, for future propose
  const signup = async (email, password) => {
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
    setUser(response.user)
    return response.user
  }

  // Create account
  const createAccount = async (user = {}) => {
    let data = await JSON.stringify({
      data: {
        companyName: user.companyName,
        countryISOCode: 'US',
        streetAddress: '123 1st St',
        city: 'Seattle',
        state: 'WA',
        zipCode: '98000',
        totalSeats: 1000,
        timezone: '1',
        preferredLanguage: '<string>',
        numberOfSeats: '<integer>',
      },
      owner: { firstName: user.firstName, lastName: user.lastName, emailAddress: user.email },
      ownerPassword: user.password,
    })

    return await $unsecurePost(`${api}/account`, data)
      .then((result) => result)
      .catch((err) => err)
  }

  // Validate email with confirm code
  const confirmCode = async (accountId, code) => {
    return await $unsecurePost(`${api}/manage/confirmCode`, { code: code, accountId: accountId })
      .then((result) => result)
      .catch((err) => err)
  }

  // Sign out
  const logout = async () => {
    return await firebase
      .auth()
      .signOut()
      .then(() => {
        clearStorage()
      })
  }

  const clearStorage = async () => {
    window.localStorage.removeItem('idToken')
    window.localStorage.removeItem('tenant')
    window.localStorage.removeItem('tenants')
    window.sessionStorage.removeItem('logged')
    window.localStorage.removeItem('user_props')
    await del('companyName')
    await del('user_props')

    setData(false)
    setUser(false)
  }

  // Send Password reset by email
  const sendPasswordResetEmail = async (email, tenantId) => {
    const auth = firebase.auth()
    auth.tenantId = tenantId

    await auth.sendPasswordResetEmail(email)
  }

  // Conform Password Reset ?
  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true
      })
  }

  // update user password
  const updateUserPassword = async (newPassword, oldPassword, email, tenant) => {
    const auth = firebase.auth()
    auth.tenantId = tenant
    const user = auth.currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(email, oldPassword)
    await user.reauthenticateWithCredential(credential)
    await user.updatePassword(newPassword)

    // also checkin to clear the password change flag
    await $post(`${apiUrl}/users`)
  }

  // Get current user auth
  const currentUser = async () => {
    let curr = await firebase.auth().currentUser
    return curr == null ? null : curr
  }

  const realtimeEntities = async (accountId) => {
    const firestore = firebase.firestore()
    firestore
      .collection('devices')
      .where('accountId', '==', accountId)
      .onSnapshot((snap) => {
        console.log(snap)
      })
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const auth = firebase.auth()
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        auth.tenantId = user.tenantId

        auth.currentUser.getIdToken(true).then((token) =>
          verifyLogin(token, auth).then((_) => {
            setUser(user)
          })
        )
      } else {
        setUser(false)
      }
    })

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [])

  // store API url
  const API = api


  // messaging stuff
  // eslint-disable-next-line
  const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
      Notification
        .requestPermission()
        .then(() => firebase.messaging().getToken())
        .then((firebaseToken) => {
          resolve(firebaseToken);
        })
        .catch((err) => {
          reject(err);
        });
    });

  // eslint-disable-next-line
  const onMessageListener = () =>
    new Promise((resolve) => {
      firebase.messaging().onMessage((payload) => {
        resolve(payload);
      });
    });

  // Return the user object and auth methods
  return {
    API,
    currentUser,
    user,
    data,
    login,
    authToken,
    signup,
    logout,
    createAccount,
    confirmCode,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateUserPassword,
    realtimeEntities,
    getAllTenants,
    getUserProps
  }
}
