import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import { useMemo } from 'react'
import queryString from 'query-string'

/**
 * @function useRouter
 *  Get the router object with useRouter
 *  Get value from query string with ...query.{queryKey}
 *  Get current pathname with ...pathname
 *  Navigate with ...push()
 *  @example https://usehooks.com/useRouter/
 * */
export function useRouter() {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      path: location.pathname.toLowerCase().replace(/\//g, ''),
      current: location.pathname.toLowerCase().split('/')[1],
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
    }
  }, [params, match, location, history])
}
