import { useAuth } from './useAuth'
import { useRouter } from './useRouter'
import { useEffect } from 'react'

/**
 * @function useRequireAuth aka middleware auth check
 * simple usage, just set auth = useRequireAuth()
 * if auth is null - still fetching data
 * or else hook will redirect to - by default to login page
 * @example https://usehooks.com/useRequireAuth/
 * */

const signupUrl = '/signup'
const loginUrl = '/login'
const onboardingUrl = '/onboarding'

export function useRequireAuth(redirectUrl = loginUrl) {
  const auth = useAuth()
  const router = useRouter()

  // If auth.user is false that means we're not
  // logged in and should redirect.
  useEffect(() => {
    if (auth.user === false) {
      if (router.pathname !== redirectUrl && router.pathname !== onboardingUrl) {
        router.push(redirectUrl)
      }
    } else if (auth.user && (router.pathname === signupUrl || router.pathname === loginUrl)) {
      if (window.sessionStorage.storeCurrentPagePath) {
        let lastPage = window.sessionStorage.storeCurrentPagePath
        // window.sessionStorage.removeItem('lastPageBeforeLogout')
        router.push(lastPage)
      } else {
        router.push('/')
      }
    }
  }, [auth, router, redirectUrl])

  return auth
}
