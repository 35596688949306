import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'

import { HashRouter } from 'react-router-dom'
import './assets/base.scss'
import Main from './Pages/Main'
import configureStore from './config/configureStore'
import { Provider } from 'react-redux'
import { ProvideAuth } from './components/hooks/useAuth'

const store = configureStore()
const rootElement = document.getElementById('root')

serviceWorker.unregister()

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <ProvideAuth>
        <HashRouter>
          <Component />
        </HashRouter>
      </ProvideAuth>
    </Provider>,
    rootElement
  )
}

renderApp(Main)


serviceWorker.register()
serviceWorker.registerServiceWorker()