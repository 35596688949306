import {Redirect, Route} from 'react-router-dom'
import React, {lazy, Suspense, useState} from 'react'

// Import components
import Loader from 'react-loaders'
import {ToastContainer} from 'react-toastify'
import {useRouter} from "../../components/hooks/useRouter";
import Logout from "../../Pages/Account/Logout";
import {$get} from "../../components/hooks/useAxiosV2";
import {apiUrl} from "../../config/apiOptions";

// Import page components
const Login = lazy(() => import('../../Pages/Account/Login'))
const ChangePassword = lazy(() => import('../../Pages/Account/ChangePassword'))
const Onboarding = lazy(() => import('../../Pages/Account/Onboarding'))
const MainRouter = lazy(() => import('../../Pages/MainRouter'))

// Main Loading screen
const LoadingScreen = () => (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-scale-ripple-multiple" active />
      </div>

      <h5 className="mt-3 pt-3">Loading...</h5>
    </div>
  </div>
)

// Suspense router - Lazy loading fallback
const PowerSuspense = ({path, component}) => {

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Route path={path} component={component} />
    </Suspense>
  )
}

// get Account Info from API
const getAccountInfo = async (uid) => {
  const requestURL = `${apiUrl}/users/${uid}`
  const request = await $get(requestURL)

  if(request.metadata){
    return request
  }
}

const AppMain = ({auth}) => {
  const [passChange, setPassChange] = useState(null)
  const router = useRouter()

  // Wait for callback
  if (auth.user == null) {
    return <LoadingScreen />
  }

  // Get shouldChangePassword
  if(!passChange && auth.user.uid) {
    getAccountInfo(auth.user.uid).then(res => {
        setPassChange(res.metadata)
    })
  }

  // check if new user to change password
  if (passChange && passChange.shouldChangePassword) {
    passChange.shouldChangePassword = false
    if(router.current !== 'password' && router.current !== 'logout'){
      router.push('/password')
    }
    return (
      <>
        <PowerSuspense path={'/password'} component={ChangePassword}/>
        <PowerSuspense path={'/logout'} component={Logout} />
      </>
    )

  }
  return (
    <>
      {/* If user logged in */}
      {auth.user &&
      <>
        <PowerSuspense path={'/'} component={MainRouter}/>
        <Route exact path={["/", "/#/"]} render={() => (
          <Redirect to="/dashboards" />
        )} />

      </>
      }

      {/* If user logged out */}
      {!auth.user &&
        <>
          <PowerSuspense path={'/Login'} component={Login} />
          <PowerSuspense path={'/Onboarding'} component={Onboarding} />
        </>
      }

      {/* Toast Container */}
      <ToastContainer />
    </>
  )
}

export default AppMain
