import axios from 'axios'

/**
 * Submit secure post
 * @param url
 * @param data
 * @returns {Promise<T>}
 */
export const $post = async (url, data) => {
  const token = await window.localStorage.idToken

  return await axios({
    method: 'POST',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    withCredentials: false,
  })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response)
    })
}

/**
 * Submit Unsecure post
 * @param url
 * @param data
 * @param _secure
 * @returns {Promise<T>}
 */
export const $unsecurePost = async (url, data, _secure = false) => {
  return await axios({
    method: 'POST',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: false,
  })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response)
    })
}

/**
 * Submit Get data
 * @param url
 * @returns {Promise<T>}
 */
export const $get = async (url) => {
  const token = await window.localStorage.idToken

  return await axios({
    url: url,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response.data)
    })
}
