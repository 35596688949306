import axios from 'axios'

const useAxios = () => {
  // Submit Post dat
  const $post = async (url, data) => {
    const token = await window.localStorage.idToken

    return await axios({
      method: 'POST',
      url: url,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: false,
    })
      .then((response) => {
        // console.dir(response)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response)
      })
  }

  // Unsecure post
  const $unsecurePost = async (url, data, _secure = false) => {
    return await axios({
      method: 'POST',
      url: url,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: false,
    })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response)
      })
  }

  // Submit Get data
  const $get = async (url) => {
    const token = await window.localStorage.idToken

    return await axios({
      url: url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        detectError(error.response.data.error)
        return Promise.reject(error.response.data)
      })
  }

  const detectError = (errorMessage) => {
    if (errorMessage.includes('auth/id-token-expired')) {
      console.error('Need to create auto logout when auth/id-token-expired')
    }
  }

  return {
    $post,
    $get,
    $unsecurePost,
  }
}

export default useAxios
