import React from 'react'
import { useAuth } from '../../components/hooks/useAuth'
import Loader from 'react-loaders'
import LoadingOverlay from 'react-loading-overlay'
const Logout = () => {
  const auth = useAuth()
  auth.logout().then()

  return (
    <>
      <LoadingOverlay
        tag="div"
        active={true}
        styles={{
          overlay: (base) => ({
            ...base,
            position: 'absolute',
            background: 'rgba(246,249,255,0.5)',
            opacity: 0.9,
            height: 'calc(100vh - 120px)',
          }),
        }}
        spinner={<Loader active type="ball-scale-ripple-multiple" />}
      >
        <div> </div>
      </LoadingOverlay>
    </>
  )
}

export default Logout
