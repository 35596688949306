import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'

import ResizeDetector from 'react-resize-detector'

import AppMain from '../Layout/AppMain'
import { useRequireAuth } from '../components/hooks/useRequireAuth'
import { useRouter } from '../components/hooks/useRouter'

const storeCurrentPagePath = (pathname) => {
  const blackList = ['/login', '/logout', '/onboarding', '/', '/password']
  if (!blackList.includes(pathname)) window.sessionStorage.storeCurrentPagePath = pathname
}

const Main = (props) => {
  const closedSmallerSidebar = false
  let { colorScheme, enableFixedHeader, enableFixedSidebar, enableFixedFooter, enableClosedSidebar, enableMobileMenu, enablePageTabsAlt } = props
  const auth = useRequireAuth()

  const { pathname } = useRouter()
  storeCurrentPagePath(pathname)

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <>
          <div
            className={cx(
              'app-container app-theme-' + colorScheme,
              { 'fixed-header': enableFixedHeader },
              { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
              { 'fixed-footer': enableFixedFooter },
              { 'closed-sidebar': enableClosedSidebar || width < 1250 },
              {
                'closed-sidebar-mobile': closedSmallerSidebar || width < 1250,
              },
              { 'sidebar-mobile-open': enableMobileMenu },
              { 'body-tabs-shadow-btn': enablePageTabsAlt }
            )}
          >
            <AppMain auth={auth} />
          </div>
        </>
      )}
    />
  )
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
})

export default withRouter(connect(mapStateToProp)(Main))
